import { createContext, useEffect, useState } from 'react';

import { ApiSettings } from '@hultafors/shared/types';

import {
  Feature,
  FeatureToggleFragment,
  FooterFragment,
  GlobalFragment,
  MenuItemFragment,
  SiteLocaleFragment,
} from '@hultafors/hultafors/types';

export interface GlobalContextValue {
  settings: ApiSettings;
  globalContent: GlobalFragment;
  setGlobal?(value: GlobalFragment): void;
  menuData: MenuItemFragment[];
  setAllMenuItems?(value: MenuItemFragment[]): void;
  footerData: FooterFragment;
  setFooter?(value: FooterFragment): void;
  featureToggle?: FeatureToggleFragment;
  isEnabled(feature: string): boolean;
  siteLocale: SiteLocaleFragment;
}

export const GlobalContext = createContext<GlobalContextValue | null>(null);

export interface GlobalProviderProps {
  settings: ApiSettings;
  global: GlobalFragment;
  allMenuItems: MenuItemFragment[];
  footer: FooterFragment;
  featureToggle: FeatureToggleFragment;
  children?: React.ReactNode;
  siteLocale: SiteLocaleFragment;

}

export const GlobalProvider: React.FC<GlobalProviderProps> = ({
  global: initialGlobal,
  allMenuItems: initialAllMenuItems,
  footer: initialFooter,
  featureToggle: initialFeatureToggle,
  settings,
  children,
  siteLocale,
}) => {
  const [global, setGlobal] = useState<GlobalFragment>(initialGlobal);
  const [allMenuItems, setAllMenuItems]
    = useState<MenuItemFragment[]>(initialAllMenuItems);
  const [footer, setFooter] = useState<FooterFragment>(initialFooter);
  const [featureToggle] = useState<FeatureToggleFragment>(initialFeatureToggle);

  useEffect(() => {
    if (initialGlobal) {
      setGlobal(initialGlobal);
    }
  }, [initialGlobal]);

  useEffect(() => {
    if (initialAllMenuItems) {
      setAllMenuItems(initialAllMenuItems);
    }
  }, [initialGlobal]);

  useEffect(() => {
    if (initialFooter) {
      setFooter(initialFooter);
    }
  }, [initialFooter]);

  const isEnabled = (feature: Feature) => {
    return featureToggle?.[feature];
  };
  return (
    <GlobalContext.Provider
      value={{
        settings,
        isEnabled,
        globalContent: global,
        setGlobal,
        menuData: allMenuItems,
        setAllMenuItems,
        footerData: footer,
        setFooter,
        siteLocale,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
